import React from "react";
import * as styles from "../../css/blog-card.module.css"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const BlogCard = ({ blog }) => {
    const { slug , title , image , published }= blog
    const image_info = getImage(image)
    return (
        <article className={styles.blog}>
            <div className={styles.imgContainer}>
            <GatsbyImage image={image_info} className={styles.img} alt="single post"/>
                <AniLink fade className={styles.link} to={`/blog/${slug}`}>
                    read more
                </AniLink>
                <h6 className={styles.date}>
                    {published}
                </h6>
            </div>
            <div className={styles.footer}>
                <h4>{title}</h4>
            </div>
        </article>
    )
}

export default BlogCard

