import React                from "react";
import Layout               from "../components/Layout";
import StyledHero           from "../components/StyledHero"
import { graphql }          from 'gatsby'
import BlogList             from "../components/blog/BlogList";
import Seo                  from "../components/SEO";

 
const blog = ({data}) =>
{
    return (
        <div>
            <Layout>
                <Seo title="Blog" description="A beautiful blog"/>
                <StyledHero img = {data.blogBcg.childImageSharp.fluid}/>
                <BlogList/>
                
                
            </Layout>
        </div>
    )
}
export default blog;

export const query = graphql`
    query 
    {
        blogBcg: file(relativePath: {eq: "blogBcg.jpeg"}) 
        {
            childImageSharp 
            {
                fluid(quality: 90 , maxWidth:4160)
                {
                    ...GatsbyImageSharpFluid_withWebp
                }
            }
        }
    }

`;